#component_header {
	
	.top {
		.choose-city {
			svg {
				left: 0;
			}
		}
		
		.link {
			margin-left: 20px;
			padding: 15px 0;
			
			.bg {
				border-radius: 3px 0 0 3px;
				bottom: 0;
				left: 0;
				position: absolute;
				right: -9999px;
				top: 0;
			}
			
			&:hover {
				color: #fff;
			}
		}
	}
	
	.line {
		background: #282828;
		height: 1px;
	}
	
	.bottom {
		padding: 30px 0;
		
		.logo {
			width: 210px;
			
			img {
				width: 100%;
			}
		}
		
		nav {
			margin-top: 5px;
			.sub-link {
				position: relative;
				margin-right: 10px;
				padding-bottom: 5px;
				> span {
					display: flex;
					align-items: center;
					gap: 5px;
					cursor: default;
					&::after {content: '';	
						transform: rotate(45deg);
						border: solid #fff;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 3px;
						margin-top: -3px;
					}
				}
				ul {
					position: absolute;
					top: 100%;
					left: 0;
					transition-duration: .15s;
					background-color: #fff;
					box-shadow: 3px 3px 10px rgba(0,0,0,.35);
					border-radius: 10px;
					padding: 5px 10px;
					opacity: 0;
					visibility: hidden;
          z-index: 100;
					a {
						color: #000;
						transition-duration: .15s;
						white-space: nowrap;
						&:hover {
							color: #a20202;
						}
					}
					li + li {
						margin-top: 4px;
					}
				}
				&:hover {
					ul {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			a {
				margin-right: 20px;

				&:hover {
					color: $colorGray;
				}
				
				&._phone {
					margin-right: 40px;
					
					svg {
						left: 0;
					}
				}
				
				.dot {
					border-radius: 100%;
					height: 4px;
					right: -22px;
					width: 4px;
				}
			}
		}
		
		.search-by-auto {
			border-right: 3px;
			padding: 13px 15px 13px 14px;
			border-radius: 3px;
		}
		
		.search {
			border-bottom: 1px solid #282828;
			border-top: 1px solid #282828;
			font-size: 14px;
			width: 195px;
			
			svg {
				left: 14px;
			}
			
			input {
				padding: 12.5px 0 12.5px 44px;
			}
		}
		
		.btn-wrap {
			.button {
				border-radius: 3px;
				height: 44px;
				width: 44px;
				
				svg {
					height: 100%;
					padding: 12px;
					width: 100%;
				}
				.counter {
					position: absolute;
					right: 6px;
					font-size: 12px;
					background: $red;
					border-radius: 10px;
					width: 17px;
					font-weight: 600;
					color: #fff;
				}
			}
		}
		
		&._sticky {
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
			z-index: 100;
			padding: 15px 0;
		}
	}
	
	.city-name {
		max-width: 150px;
		word-break: break-all;
	}
	
	#menu-btn {
		padding: 10px;
		
		.close {
			display: none;
		}
		
		&._active {
			padding: 11px;
			
			.burger {
				display: none;
			}
			
			.close {
				display: block;
			}
		}
	}
	
	.search-icon {
		height: 20px;
		width: 20px;
		//fill: #7ad06d;
		
		svg {
			width: 100%;
			height: 100%;
		}
	}
	
	@media (max-width: $mediaXxl) {
		.top {
			.link {
				margin-left: 10px;
			}
		}
		
		.bottom {
			padding: 30px 0;
			
			nav {
				a {
					margin-right: 10px;
				}
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.top {
			.link {
				margin-left: 15px;
				
				&:not(._acent) {
					display: none;
				}
			}
		}
		
		.bottom {
			padding: 15px 0;
		}
	}
	
	@media (max-width: $mediaLg) {
		.top {
			.link {
				padding: 12px 0;
			}
		}
		
		.bottom {
			padding: 15px 0;
			
			.logo {
				width: 190px;
			}
			
			.search {
				input {
					padding: 10.5px 0 10.5px 44px;
				}
			}
			
			.btn-wrap {
				.button {
					height: 40px;
					width: 40px;
					
					svg {
						padding: 10px;
					}
				}
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		.bottom {
			padding: 15px 0;
			
			.logo {
				width: 160px;
			}
		}
	}
	
	@media (max-width: $mediaSm) {
		.city-name {
			max-width: 130px;
		}
	}
	
	@media (max-width: $media475) {
		.top {
			.link {
				margin-left: 15px;
			}
		}
		
		.bottom {
			.logo {
				width: 100px;
			}
			
			.btn-wrap {
				.button {
					height: 34px;
					width: 34px;
					
					svg {
						padding: 9px;
					}
				}
			}
		}
		
		#menu-btn {
			padding: 6px;
			
			&._active {
				padding: 9px;
			}
		}
	}

	@media (max-width: $media375) {
		.city-name {
			max-width: 100px;
		}
	}

  // Поиск в мобиле 
  .top > .container {
    position: relative;
  }

  .hidden-search-mobile {
    position: absolute;
    inset: 0;
    z-index: 1000;
    display: none;
  }

  .hidden-search-mobile form,
  .hidden-search-mobile__input {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .hidden-search-mobile input {
    background-color: #fff;
    padding: 0 35px 0 10px;
  }
  .hidden-search-mobile button {
    position: absolute;
    z-index: 2;
    inset: 0 10px 0 auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
  }
}

#mobile-menu {
	left: 0;
	position: fixed;
	z-index: 1000;
	background: #191919;
	display: none;
	
	.search {
		margin-bottom: -1px;
		border-bottom: 1px solid #333;
	}
	
	.top-links {
		a {
			text-align: right;
			display: block;
			padding: 14px 0;
			border-top: 1px solid #333;
		}
	}
	
	.bottom-links {
		color: #888;
		
		a {
			text-align: right;
			display: block;
			margin-top: 15px;
		}
	}
	
	.social-links {
		a {
			width: 26px;
			height: 26px;
			border-radius: 3px;
			overflow: hidden;
			display: block;
		}
	}
	
	&._visible {
		display: flex;
	}
}
